<template>
 <div>
 <Content :route="'/rotas'" :action="action" title="Dicionários" :selected="selected" @event="saveDictionary">
 <template #selects>
  <b-row class="justify-content-start ml-2" style="flex-grow: 1; flex-direction: row; align-items: center; margin: 0; place-content: center;">
    <b-col md="3" cols="9" class="my-2">
      <b-form-group class="mb-0">
      <!--<b-form-select
        :options="allEnterprisesAsOptions"
        v-model="selectedEnterprise"
        @change="getSelectedEnterprise"
      >
        <template #first>
          <b-form-select-option :value="null">Selecionar empresa</b-form-select-option>
        </template>
      </b-form-select>-->
      <v-select autocomplete filterable
          :options="allEnterprisesAsOptions.map(o => ({ label: o.text, code: o.value }))"
          placeholder="Selecionar Empresa"
          v-model="selectedEnterpriseHolder"
          @input="getSelectedEnterprise($event.code)"
          style="width: 200px"
        >
        <div slot="no-options">Nenhuma opção encontrada</div>
      </v-select>
    </b-form-group>
      </b-col>
    <b-col md="3" cols="9" class="my-2" v-if="selectedEnterprise">
      <SelectSystem
        :options="allSystemsAsOptions"
        :selectedSystem="selectedSystem"
        @system-selected="getSelectedSystem"
        ref="ss"
      />
      </b-col>

      <b-col md="3" cols="9" class="my-2" v-if="selectedSystem">
      <SelectRoutes
        :routes="allRoutesAsOptions"
        :selectedRoute="selectedRoute"
        @route-selected="onRouteSelected"
        ref="sr"
      />
      </b-col>
      <b-col md="3" cols="9" class="my-2" v-if="selectedRoute">
        <div @click="modalConsulta(selectedSystem, selectedRoute, selectedEnterprise)" style="background-color: #00DDEE; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" class="title-btn">
            <span style="font-size: small;" class="label">Visualizar consulta</span>
        </div>
      </b-col>
  </b-row>
 </template>
    <b-row class="card dicionario_card mb-4" id="clonar_dicionarios">
      <b-col style="place-self: center; margin-top: 2%;" md="4" cols="12">
            <h4 class="title font-weight-bold">
              Clonar dicionários
            </h4>
            <span></span>
      </b-col>
      <b-col :md="$route.name === 'CadastrarModulos'?'2':'3'" cols="6">
        <b-form-group>
            <label class="title" style="font-size: 20px;">
              ERP:
          </label>
            <span></span>
          <div
            class="d-flex flex-row justify-content-around align-items-center"
          >
            <b-form-select :options="allSystemsAsOptions" v-model="selectedSystemToCopy">
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Selecione um usuário para clonar a tabela
                  atual</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </b-form-group>
      </b-col>
      <b-col :md="$route.name === 'CadastrarModulos'?'2':'3'" cols="6">
        <b-form-group>
            <label class="title" style="font-size: 20px;">
              Rota:
            </label>
            <span></span>
          <div
            class="d-flex flex-row justify-content-around align-items-center"
          >
            <b-form-select :options="allRoutesAsOptionsToClone" v-model="selectedRouteToCopy">
              <template #first>
                <b-form-select-option :value="undefined" disabled
                  >Selecione um usuário para clonar a tabela
                  atual</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </b-form-group>
      </b-col>
      <b-col style="place-self: center; margin-top: 2%;" md="2" cols="6">
        <!-- :click-handler="updateRoute(selectedRoute)"> -->
        <LoadingButton :isLoading="loadingUpdateRoute" :button-class="'btn-turquesa'" style="background-color: #00DDEE; width: fit-content; border-color: #00DDEE;" @event="updateRoute">
        importar
      </LoadingButton>
      </b-col>
  </b-row>
    <b-row class="card dicionario_card" id="relacionamento">
      <b-col cols="12" class="relacionamento">
        <h2 class="title font-weight-bold">Relacionamento</h2>
          <b-col cols="3">
            <v-select taggable push-tags autocomplete
              id="sort-by-select"
              :options="tableOptions"
              v-model="tableSelected"
              @input="listColumns(tableSelected)"></v-select>
          </b-col>
          <span role="button" @click="goTo('Configurar', selectedRoute)">
          <i
            style="background-color: #00DDEE"
            class="material-icons btn-icon"
            >settings</i>
            Configuração
        </span>
      </b-col>
      <Preload ref="card-preload" :optionsSystem="systemServices" :optionsDatalake="datalakeServices"></Preload>
    </b-row>
  </Content>
  <b-modal size="xl" centered id="modal-consulta">
      <template #modal-header>
            <b-row class="mb-1 text-center" style="width: 100%; margin: auto;">
             <b-col cols="6">
              <h2 class="title">Resultado da Rotina</h2>
             </b-col>
            </b-row>
      </template>
      <ERPQuery :clientId="query.clientId" :routekey="query.route" :systemkey="query.system" :entrepriseName="query.enterprise" :modaltitle="''" :usertitle="'Cliente'" :systemtitle="'Sistema'" :enterprisetitle="'Empresa'" :routetitle="'Rota'"></ERPQuery>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
 </div>
</template>
<script>
import { request, api } from '@/services'
import { toast } from '@/utils'
import SelectSystem from '@/components/content/SelectOptions.vue'
import SelectRoutes from '@/components/content/SelectRoutes.vue'
import Content from '../components/content/index'
import Preload from '../components/cards/Preload.vue'
import ERPQuery from '@/components/Modals/ERPQuery'
import LoadingButton from '../components/Shared/LoadingButton.vue'
export default {
  components: {
    Content, Preload, SelectSystem, SelectRoutes, ERPQuery, LoadingButton
  },
  data: () => ({
    services: [],
    services2: [],
    selected: undefined,
    selectedSystem: null,
    selectedEnterprise: null,
    selectedEnterpriseHolder: null,
    selectedRoute: null,
    selectedSystemToCopy: null,
    selectedRouteToCopy: null,
    tableOptions: [],
    tableSelected: null,
    datalakeServices: [],
    systemServices: [],
    totalRows: 1,
    query: {},
    edit: null,
    items: [],
    loadingUpdateRoute: false,
    selectsLoaded: false,
    oldTable: null
  }),
  computed: {
    allSystems () {
      return Object.values(this.$store.state.sistemas?.items?.groups || [])
    },
    allSystemsAsOptions () {
      return this.allSystems.map(a => ({ value: a.id, text: a.key }))
    },
    allRoutes () {
      return (this.$store.state.rotas?.items?.objects || [])
    },
    allRoutesAsOptions () {
      return this.allRoutes
        .filter(a => !this.selectedSystem || a.groups.find(b => b.id === this.selectedSystem))
        .map(a => ({ value: a.id, text: a.key }))
    },
    allRoutesAsOptionsToClone () {
      return this.allRoutes
        .filter(a => !this.selectedSystemToCopy || a.groups.find(b => b.id === this.selectedSystemToCopy))
        .map(a => ({ value: a.id, text: a.key }))
    },
    allEnterprises () {
      return (this.$store.state.empresas?.items?.users || [])
    },
    allEnterprisesAsOptions () {
      return this.allEnterprises.map(a => ({ value: a.id, text: a.name }))
    },
    servicesOptions () {
      return this.services.map((s) => ({
        value: s.key
      }))
    },
    action () {
      return { options: { label: 'Salvar', icon: 'save' } }
    },
    hasData () {
      return this.totalRows > 0
    }
  },
  watch: {
    allRoutes (value) {
      return value
    },
    '$store.state.sistemas.items': {
      handler () {
        this.setSelectValues()
      },
      immediate: true
    },
    '$store.state.empresas.items': {
      handler () {
        this.setSelectValues()
      },
      immediate: true
    },
    '$store.state.rotas.items': {
      handler () {
        this.setSelectValues()
      },
      immediate: true
    }
  },
  created () {
    this.$store.dispatch('sistemas/init', { self: this })
    this.$store.dispatch('rotas/init', { self: this })
    this.$store.dispatch('empresas/init', { self: this })
  },
  methods: {
    setSelectValues () {
      if (!this.$route.query.rota || this.selectsLoaded) return
      this.selectedRoute = this.$route.query.rota
      this.selectedSystem = this.allRoutes.find(a => a.id === this.selectedRoute)?.groups?.find(a => a.type === 'ERP')?.id
      const selectedEnterpriseHolderObj = this.allEnterprises.find(e => e.groups.find(g => g.id === this.selectedSystem))
      console.log(selectedEnterpriseHolderObj)
      this.selectedEnterpriseHolder = selectedEnterpriseHolderObj ? ({ label: selectedEnterpriseHolderObj.name, code: selectedEnterpriseHolderObj.id }) : null
      this.selectedEnterprise = this.allEnterprises.find(e => e.groups.find(g => g.id === this.selectedSystem))?.id
      if (this.selectedEnterprise) {
        this.onRouteSelected(this.selectedRoute)
        this.selectsLoaded = true
      }
      /*
      if (this.selectedRoute) {
        this.$refs.sr.refreshModel()
      }
      if (this.selectedSystem) {
        this.$refs.ss.refreshModel()
      }
      */
    },
    updateRoute (route) {
      route = this.selectedRoute
      const data = {
        base_object: this.$store.state.rotas.items.objects.filter(a => a.key === route).map(a => a.id)[0],
        new_object: this.$store.state.rotas.items.objects.filter(a => a.key === this.selectedRouteToCopy).map(a => a.id)[0]
      }
      this.loadingUpdateRoute = true
      api.post('/object/copy', data).then(response => {
        toast(this, 'success', 'Rota copiada', 'Rota copiada com sucesso')
        this.loadingUpdateRoute = false
        console.log(response)
      })
    },
    goTo (route, id) {
      if (!id) return toast(this, 'info', 'Dicionário', 'Primeiro selecione uma rota para ter acesso ao modo avançado')
      this.$router.push({ name: route, query: { id, key: this.allRoutes.find(a => a.id === id)?.key } })
    },
    modalConsulta (system, route, enterprise) {
      this.$bvModal.show('modal-consulta')
      this.query = {
        system: this.allSystems.find(a => a.id === system)?.key,
        route: this.allRoutes.find(a => a.id === route)?.key,
        enterprise: this.allEnterprises.find(a => a.id === enterprise)?.name,
        clientId: this.allEnterprises.find(a => a.id === enterprise)?.tenant_id
      }
    },
    getSelectedSystem (value) {
      this.selectedRoute = null
      this.selectedSystem = value
      this.$store.dispatch('erp', value)
    },
    getSelectedEnterprise (value) {
      this.selectedEnterprise = value
      this.selectedSystem = null
      this.selectedRoute = null
      this.$store.dispatch('enterprise', value)
      console.log(this.$store.getters.enterprise)
    },
    onRouteSelected (routeId) {
      this.selectedRoute = routeId
      this.getDictionary()
      this.getTableName(routeId)
      this.$store.dispatch('dictionary', this.selectedRoute)
    },
    getTableName (routeId) {
      request.post('datalake/getTablename', {
        object_name: this.allRoutes.find(a => a.id === routeId)?.key
      },
      (response) => {
        // toast(this, 'success', 'tabelas listadas com sucesso')
        // console.log(response.data.split('_'))
        this.tableSelected = response.data // .split('_')?.slice(1)?.join('_')
      },
      (error) => {
        toast(this, 'danger', 'Nome da tabela não encontrado', error)
        return Promise.reject(error)
      })
    },
    getDictionary () {
      const route = this.allRoutes.find(a => a.id === this.selectedRoute)?.key?.split('_')?.[1]
      const erp = this.allSystems.find(a => a.id === this.selectedSystem)?.key
      const company = this.selectedEnterprise // this.allEnterprises.find(a => a.id === this.selectedEnterprise).id
      request.post('datalake/listtables', {
        tableName: route
      },
      (response) => {
        toast(this, 'success', 'tabelas listadas com sucesso')
        this.tableOptions = response.data
      },
      (error) => {
        toast(this, 'danger', 'tabelas não foram listadas', error)
        return Promise.reject(error)
      })

      request.post('/erp/columnsSistem', {
        user_id: company,
        call: route,
        erp
      },
      (response) => {
        toast(this, 'success', 'colunas do sistemas listadas com sucesso')
        console.log(Object.values(response.data).map(a => Object.values(a)))
        this.systemServices = Object.values(response.data).map(a => Object.values(a))[0] // Object.entries(response.data).map(a => a[1])[0]
      },
      (error) => {
        toast(this, 'danger', 'colunas do sistemas não foram listadas', error)
        return Promise.reject(error)
      })

      request.post('/erp/columnsDictionary', { call: route, erp }, res => {
        this.$refs['card-preload'].setValues(res.data.dictionary)
      }, error => {
        toast(this, 'danger', 'colunas do sistemas não foram listadas', error)
      })
    },
    listColumns (value) {
      console.log(value)

      if (this.tableOptions.includes(value)) {
        request.post('datalake/listcolumns', {
          tableName: value
        },
        (response) => {
          this.oldTable = value
          toast(this, 'success', 'Colunas listadas com sucesso')
          this.datalakeServices = response.data || undefined
        },
        (error) => {
          toast(this, 'danger', 'Dicionário não foi criado', error)
          return Promise.reject(error)
        })
      } else {
        console.log(value, this.tableSelected)
        const mainFunction = this.$store.state.rotas.items.objects?.find(r => r.id === this.selectedRoute)?.extra_fields?.find(item => item.key === 'mainFunction')?.value?.[0]
        request.post('datalake/updateTableName', {
          tableName: value,
          mainFunction: mainFunction,
          object_id: this.selectedRoute
        },
        (response) => {
          toast(this, 'success', 'Nome da tabela atualizada com sucesso')
          this.datalakeServices = response.data
          // this.tableOptions = this.tableOptions.filter(f => f !== this.tableSelected)
        },
        (error) => {
          toast(this, 'danger', 'Dicionário não foi criado', error)
          return Promise.reject(error)
        })
      }
    },
    saveDictionary () {
      request.put(`object/update/${this.selectedRoute}`, {
        preload: this.$refs['card-preload'].generatePHP
      },
      (response) => {
        toast(this, 'success', 'Dicionário', 'cadastrado com sucesso')
        this.tableOptions = response.data
        console.log(response)
      },
      (error) => {
        toast(this, 'danger', 'Dicionário não foi criado', error)
        return Promise.reject(error)
      })
      const mainFunction = this.$store.state.rotas.items.objects?.find(r => r.id === this.selectedRoute)?.extra_fields?.find(item => item.key === 'mainFunction')?.value?.[0]
      request.post('datalake/updateTableName', {
        tableName: this.tableSelected,
        mainFunction: mainFunction,
        object_id: this.selectedRoute
      },
      (response) => {
        // toast(this, 'success', 'Nome da tabela atualizada com sucesso')
        this.datalakeServices = response.data
        // this.tableOptions = this.tableOptions.filter(f => f !== this.tableSelected)
      },
      (error) => {
        // toast(this, 'danger', 'Dicionário não foi criado', error)
        return Promise.reject(error)
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../style/mixins.styl';
@import '../style/colors.styl';

.select-title{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 6vh;
    width: min-content
    align-content: center;
}

#clonar_dicionarios {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 1%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}

.dicionario_card{
  max-width: 90vw;
  margin: auto;
  border-radius: 15px
}

#relacionamento {
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 50px;
    padding: 5%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
}
.relacionamento {
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
    width: 95%;
    padding: 1%;}

.h-separator {
  display: flex;

  .title {
    padding: 0px 10px;
    font-size: 14px;
    color: light;
    font-weight: bold;
    border-radius: 1rem;
    background: primary;
  }

  span {
    align-self: center;
  }

  span:last-child {
    border: 1px dashed primary;
    height: 1px;
  }

  span:last-child {
    flex-grow: 1;
  }
}

.btns
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  align-content: center;

.title-btn
    background-color primary
    color white
    border-radius 8px
    padding 10px 12px
    cursor pointer
    transition 0.2s

      &:hover
      background-color alpha(primary, 0.8)

      .label
        margin-left 5px

      span, .icon-btn
        vertical-align middle
        no-select()

.btn-service {
  float: left;
  margin-left: 1px;
  border-radius: 50%;
  margin-top: 20px;
}

.service {
  width: 50%;
  float: right;
}

.btn-icon-red {
  background-color: red !important;
}

.table {
  color: greyLight !important;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
  background-color: light !important;
  color: black;
}

.table td, .table th, .table thead th {
  border: none;
  white-space: nowrap;
}

.table thead th {
  padding: 0 0.75rem;
  font-size: 20px;
}

.table tr {
  // background-color content-base-theme()
  background-color: primary;
  transition: 0.4s;
}

radius = 10px;

.table tr td:first-child {
  border-top-left-radius: radius;
  border-bottom-left-radius: radius;
  width: 8%;
}

.table tr td:last-child {
  border-top-right-radius: radius;
  border-bottom-right-radius: radius;
}

.table th div {
  nowrap();
}

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: secondary !important;
}

.table.b-table tr {
  .btn-actions {
    min-width: 64px;
  }

  i {
    opacity: 0;
    transition: 0.3s;
    background-color: green;
  }

  &:hover {
    i {
      opacity: 1;
    }
  }
}

.row-title
  flex-direction row

  @media (max-width: md)
    flex-direction column
</style>
<style lang="stylus">

.vs__selected-options
  flex-wrap: nowrap

.vs__dropdown-toggle
  width: 100%
  height 38px
  min-width: 100%
  background-color #f2f2f2
  border-color: #adb5bd;

.vs__selected
  color black

</style>
