<template>
      <b-form-group class="mb-0">
        <!--<b-form-select
          :options="routes"
          v-model="selected"
          @change="onRouteSelected"
        >
          <template #first>
            <b-form-select-option :value="null">Selecionar Rota</b-form-select-option>
          </template>
        </b-form-select>-->
        <v-select autocomplete filterable
          :options="routes.map(o => ({ label: o.text, code: o.value }))"
          placeholder="Selecionar Rota"
          v-model="selected"
          @input="onRouteSelected($event.code)"
          style="width: 200px"
        >
        <div slot="no-options">Nenhuma opção encontrada</div>
      </v-select>
      </b-form-group>
  </template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      default: () => [], // O valor default é um array vazio
      required: true
    },
    selectedRoute: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      selected: this.selectedRoute
    }
  },
  watch: {
    selectedRoute: {
      handler (newValue) {
        const obj = this.routes?.find(o => o.value === newValue)
        this.selected = obj ? ({ label: obj.text, code: obj.value }) : null
      },
      immediate: true
    }
  },
  methods: {
    onRouteSelected (routeId) {
      this.$emit('route-selected', routeId) // Emitir o evento com o ID da rota selecionada
    },
    refreshModel () {
      if (typeof this.selectedRoute !== 'string') return
      const obj = this.routes?.find(o => o.value === this.selectedRoute)
      this.selected = obj ? ({ label: obj.text, code: obj.value }) : null
    }
  }
}
</script>
<!--
<style lang="stylus" scoped>
.select
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 6vh;
    width: min-content
    align-content: center;
</style>
 -->
